<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col
          ><h2>{{ (teacher.User)? teacher.User.name : '• • •' }}</h2></v-col
        >
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div>
              <v-btn rounded color="primary" :to="{name:'PageEducatorInstitutionTeacherEdit',params:{id:this.$route.params.id,parentId:this.$route.params.parentId,}}" > Edit </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ALoader :isLoading="api.isLoading"></ALoader>
      <Read v-if="!api.isLoading" :headers="headers" :data="teacher" />
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import Read from "../../components/Datatable/Read.vue";
export default {
  components: {
    Read,
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    teacher:{},
 //BOC:[api]
 api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    headers: [
      // { text: "Name", value: "userName" },
      { text: "Joined", value: "joinedStatus" },
      { text: "Last Update", value: "userTimestampUpdated" },
    ],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Teachers",
        to: {
          name: "PageEducatorInstitutionTeacher",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Teacher",
        to: {
          name: "PageEducatorInstitutionTeacherRead",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
    ]
    //EOC
  
      this.api.method = "GET";
    this.api.url =
    this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+
      this.$route.params.parentId +
      "/teacher/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.teacher = resp;
      // this.updateBreadcrumb(resp)
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
  
    //   this.breadcrumbs.push({
    //   text: "Teachers",
    //   to: { name: "PageEducatorInstitutionTeacher" ,params:{id:this.$route.params.parentId} },
    //   exact: true,
    // });
    // this.breadcrumbs.push({
    //   text: resp.User.name,
    //   to: { name: "PageEducatorInstitutionTeacherRead" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId} },
    //   exact: true,
    // });
    // },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>